import React, { FC, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { Datatable } from '../../components/DataTable';
import { DeviceColumns, Device, emptyDevice } from '../../models/device/device.model';
import { deleteDevice, duplicateDevice, getAllDevice, postDevice, updateDevice } from '../../models/device/device.repository';
import { Slider, SliderColumns, emptySlider } from '../../models/slides/slider.model';
import { deleteSlider, duplicateSlider, getAllSlider, postSlider, updateSlider } from '../../models/slides/slider.repository';
import { FieldType } from '../../components/editWindow';
import Button from '../../components/bootstrap/Button';
import Select from '../../components/bootstrap/forms/Select';
import { setDevice } from '../../models/login/Login.repository';
import { environment } from '../../common/environment';
import { deleteReceipt, downloadReceipt, getAllReceipt } from '../../models/receipt/receipt.repository';
import { Receipt } from '../../models/receipt/receipt';

interface BaseDataTableProps {
	isFluid?: boolean;
	name: string;
}

const ReceiptTable: FC<BaseDataTableProps> = ({ isFluid, name }) => {
	const { t } = useTranslation(['translation', 'menu']);
	const [devices, setDevices] = useState<Device[]>([]);
	const [selectedDevice, setSelectedDevice] = useState<number | undefined | string>();
	useEffect(() => {
		getAllDevice().then((res: Device[]) => {
			setDevices(res)
			if (res.length !== 0) {
				setSelectedDevice(`${res[0].id}`)

				setDevice(undefined)
			}
		})
	}, []);

	function getColumns() {
		SliderColumns.forEach((column) => {
			if (column.fieldType === FieldType.SingleSelect) {
				const valueForSelect = devices.map(device => {
					return { value: device.id ?? 0, text: device.device_name + " " + device.device_id }
				})
				column.valuesTeBeSelected = valueForSelect;
			}
		});
		return SliderColumns;
	}

	function changeDevice(device_id: string, deviceList: Device[]) {
		const foundDevice = deviceList.find(device => `${device.id}` === device_id)
		if (foundDevice && foundDevice.id) {
			setDevice(foundDevice.id)
			console.log(device_id)

			setSelectedDevice(`${foundDevice.id}`)
		}

	}

	function getHeaderButtons() {
		return (
			<Select
				ariaLabel={"Geräte"}
				placeholder={"Geräte"}
				list={devices.map(device => { return { value: `${device.id}`, text: device.device_name } })}
				//@ts-ignore
				onChange={(value: any) => {
					changeDevice(value.nativeEvent.target.value, devices)
				}

				}
				//@ts-ignore
				value={selectedDevice}
			/>
		)
	}

	return (
		<>{selectedDevice !== undefined &&
			<Datatable<Receipt>
				columns={getColumns()}
				getAll={getAllReceipt}
				delete={deleteReceipt}
				download={downloadReceipt}
				costumRightHeader={getHeaderButtons()}
				isFluid={true}
				name={name}
			/>}
		</>
	);
};

export default ReceiptTable;
