// Navigationsbar Dashboardssss
export const homeMenu = {
	intro: { id: 'intro', text: 'Intro', path: '#intro', icon: 'Vrpano', subMenu: null },
	bootstrap: {
		id: 'bootstrap',
		text: 'Bootstrap Components',
		path: '#bootstrap',
		icon: 'BootstrapFill',
		subMenu: null,
	},
	storybook: {
		id: 'storybook',
		text: 'Storybook',
		path: '#storybook',
		icon: 'CustomStorybook',
		subMenu: null,
	},
	formik: {
		id: 'formik',
		text: 'Formik',
		path: '#formik',
		icon: 'CheckBox',
		subMenu: null,
	},
	apex: {
		id: 'apex',
		text: 'Apex Charts',
		path: '#apex',
		icon: 'AreaChart',
		subMenu: null,
	},
};

export const dashboardMenu = {
	dashboard: {
		id: 'dashboard',
		text: 'Dashboard',
		path: '/',
		icon: 'Dashboard',
		subMenu: null,
	},
};

export const baseDataPages = {
	base: {
		id: 'base',
		text: 'baseData',
		icon: 'Extension',
	},
	baseData: {
		id: 'baseData',
		text: 'baseData',
		path: 'basedata',
		icon: 'BackupTable',
		subMenu: {
			// busstation: {
			// 	id: 'busstation',
			// 	text: 'Bus Stations',
			// 	path: 'basedata/busstation',
			// 	icon: 'money',
			// },
			settings: {
				id: 'settings',
				text: 'Settings',
				path: 'basedata/settings',
				icon: 'money',
			},
			coupon: {
				id: 'coupon',
				text: 'Coupon',
				path: 'basedata/coupon',
				icon: 'money',
			},
			type: {
				id: 'type',
				text: 'type',
				path: 'basedata/type',
				icon: 'money',
			},
			subCategory: {
				id: 'subCategory',
				text: 'subCategory',
				path: 'basedata/subCategory',
				icon: 'money',
			},
			category: {
				id: 'category',
				text: 'category',
				path: 'basedata/category',
				icon: 'money',
			},
			event: {
				id: 'event',
				text: 'event',
				path: 'basedata/event',
				icon: 'money',
			},
			loyaltyRewards: {
				id: 'loyaltyRewards',
				text: 'loyaltyRewards',
				path: 'basedata/loyaltyRewards',
				icon: 'money',
			},
			article: {
				id: 'article',
				text: 'article',
				path: 'basedata/article',
				icon: 'money',
			},
			gameRewards: {
				id: 'gameRewards',
				text: 'gameRewards',
				path: 'basedata/gameRewards',
				icon: 'money',
			},
		},
	},
};

export const adminData = {
	admin: {
		id: 'admin',
		text: 'admin',
		icon: 'admin',
	},
	booking: {
		id: 'Device',
		text: 'Geräte',
		path: 'Device',
		icon: 'list',
	},
	slider: {
		id: 'slider',
		text: 'Slider',
		path: 'slider',
		icon: 'image',
	},
	receipt: {
		id: 'receipt',
		text: 'receipt',
		path: 'receipt',
		icon: 'receipt-cutoff',
	},
	// tenant: {
	// 	id: 'tenant',
	// 	text: 'tenant',
	// 	path: 'tenant',
	// 	icon: 'storage',
	// },
};

export const demoPages = {
	login: {
		id: 'login',
		text: 'Login',
		path: 'auth-pages/login',
		icon: 'Login',
	},
	signUp: {
		id: 'signUp',
		text: 'Sign Up',
		path: 'auth-pages/sign-up',
		icon: 'PersonAdd',
	},

	page404: {
		id: 'Page404',
		text: '404 Page',
		path: 'auth-pages/404',
		icon: 'ReportGmailerrorred',
	},
};

export const layoutMenu = {
	layoutTypes: {
		id: 'layoutTypes',
		text: 'Page Layout Types',
	},
	blank: {
		id: 'blank',
		text: 'Blank',
		path: 'page-layouts/blank',
		icon: 'check_box_outline_blank ',
	},
	pageLayout: {
		id: 'pageLayout',
		text: 'Page Layout',
		path: 'page-layouts',
		icon: 'BackupTable',
		subMenu: {
			headerAndSubheader: {
				id: 'headerAndSubheader',
				text: 'Header & Subheader',
				path: 'page-layouts/header-and-subheader',
				icon: 'ViewAgenda',
			},
			onlyHeader: {
				id: 'onlyHeader',
				text: 'Only Header',
				path: 'page-layouts/only-header',
				icon: 'ViewStream',
			},
			onlySubheader: {
				id: 'onlySubheader',
				text: 'Only Subheader',
				path: 'page-layouts/only-subheader',
				icon: 'ViewStream',
			},
			onlyContent: {
				id: 'onlyContent',
				text: 'Only Content',
				path: 'page-layouts/only-content',
				icon: 'WebAsset',
			},
		},
	},
	asideTypes: {
		id: 'asideTypes',
		text: 'Aside Types',
		path: 'aside-types',
		icon: 'Vertical Split',
		subMenu: {
			defaultAside: {
				id: 'defaultAside',
				text: 'Default Aside',
				path: 'aside-types/default-aside',
				icon: 'ViewQuilt',
			},
			minimizeAside: {
				id: 'minimizeAside',
				text: 'Minimize Aside',
				path: 'aside-types/minimize-aside',
				icon: 'View Compact',
			},
		},
	},
};

export const productsMenu = {
	companyA: { id: 'companyA', text: 'Company A', path: 'grid-pages/products', subMenu: null },
	companyB: { id: 'companyB', text: 'Company B', path: '/', subMenu: null },
	companyC: { id: 'companyC', text: 'Company C', path: '/', subMenu: null },
	companyD: { id: 'companyD', text: 'Company D', path: '/', subMenu: null },
};
