import axios, { AxiosResponse } from 'axios';

import moment from 'moment';
import { Slider } from './slider.model';


export function getUrl(): string {
	return `/slider`;
}


export function postSlider(item: Slider): Promise<Slider> {
	return new Promise((resolve, reject) => {
		const formData = new FormData();
		formData.append('image', item.image);
		Object.keys(item).forEach((key) => {
			if (key !== 'image') {
				// @ts-ignore
				formData.append(key, item[key]);
			}
		});
		axios.post(getUrl(), formData, {
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		}).then((res: any) => {
			resolve(res);
		});
	});
}

export function duplicateSlider(item: Slider): Promise<Slider> {
	item.title = item.title + "Copy " + moment().format("DD.MM.YYYY HH:mm")


	return new Promise(async (resolve, reject) => {
		let createResponse = await postSlider(item)
		resolve(createResponse)
	});
}

export function getAllSlider(): Promise<Slider[]> {
	return new Promise((resolve, reject) => {
		axios.get(`${getUrl()}/all`).then((res: AxiosResponse<Slider[]>) => resolve(res.data));
	});
}

export function deleteSlider(id: number): Promise<any> {
	const options = {
		headers: {
			'Content-Type': 'application/json',
		}
	};
	return axios.delete(getUrl() + '/' + id, options);
}

export function updateSlider(item: Slider): Promise<Slider> {
	return new Promise((resolve, reject) => {
		const formData = new FormData();
		console.log(formData)
		formData.append('image', item.image);
		Object.keys(item).forEach((key) => {
			if (key !== 'image') {
				// @ts-ignore
				formData.append(key, item[key]);
			}
		});
		axios.put(`${getUrl()}/` + item.id, formData).then((res: any) => {
			resolve(res);
		});
	});
}

export function buildSlider(
	device_id: number,
	image: string,
	title: string,
	description: string,
	position: number,
): Slider {
	return {
		device_id,
		image,
		title,
		description,
		position
	};
}
