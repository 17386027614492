import React, { FC, useCallback, useState, useContext } from 'react';
import classNames from 'classnames';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import USERS, { getUserDataWithUsername } from '../../../common/data/userDummyData';
import Alert from '../../../components/bootstrap/Alert';
import Button from '../../../components/bootstrap/Button';
import Card, { CardBody } from '../../../components/bootstrap/Card';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Input from '../../../components/bootstrap/forms/Input';
import Spinner from '../../../components/bootstrap/Spinner';
import Logo from '../../../components/Logo';
import AuthContext from '../../../contexts/authContext';
import useDarkMode from '../../../hooks/useDarkMode';
import Page from '../../../layout/Page/Page';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import { loginValid, registerValid, setBaseURL } from '../../../models/login/Login.repository';

interface ILoginHeaderProps {
	isNewUser?: boolean;
}
const LoginHeader: FC<ILoginHeaderProps> = ({ isNewUser }) => {
	const { t } = useTranslation(['translation', 'menu']);

	if (isNewUser) {
		return (
			<>
				<div className='text-center h1 fw-bold mt-5'>Create Account,</div>
				<div className='text-center h4 text-muted mb-5'>Sign up to get started!</div>
			</>
		);
	}
	return (
		<>
			<div className='text-center h1 fw-bold mt-5'>{t('welcome')}</div>
			<div className='text-center h4 text-muted mb-5'>{t('signInToContinue')}</div>
		</>
	);
};

interface ILoginProps {
	isSignUp?: boolean;
}
const Login: FC<ILoginProps> = ({ isSignUp }) => {
	const { setUser } = useContext(AuthContext);


	const { darkModeStatus } = useDarkMode();

	const [signInPassword, setSignInPassword] = useState<boolean>(false);
	const [singUpStatus, setSingUpStatus] = useState<boolean>(!!isSignUp);

	const navigate = useNavigate();
	const handleOnClick = useCallback(() => navigate('/'), [navigate]);




	const usernameCheck = (username: string) => {
		return !!getUserDataWithUsername(username);
	};

	const passwordCheck = (username: string, password: string) => {
		return getUserDataWithUsername(username).password === password;
	};

	const formikRegister = useFormik({
		enableReinitialize: true,
		initialValues: {
			registerEmail: 'kemal@kemal.de',
			registerFirstName: 'kemal',
			registerLastName: 'Fathulla',
			registerPassword: 'adminadmin',
		},
		validate: (values) => {
			const errors: { registerEmail?: string; registerFirstName?: string, registerLastName?: string, registerPassword?: string } = {};

			if (!values.registerEmail) {
				errors.registerEmail = 'Required';
			}

			if (!values.registerFirstName) {
				errors.registerFirstName = 'Required';
			}
			if (!values.registerLastName) {
				errors.registerLastName = 'Required';
			}
			if (!values.registerPassword) {
				errors.registerPassword = 'Required';
			}

			return errors;
		},
		validateOnChange: false,
		onSubmit: async (values) => {
			try {
				const registerResponse = await registerValid({
					name: `${values.registerFirstName} ${values.registerLastName}`,
					email: values.registerEmail,
					password: values.registerPassword
				});
				if (setUser) {
					setUser(values.registerEmail);
				}
				setBaseURL(registerResponse.user.token)
				handleOnClick();
			} catch (err: any) {
				formikRegister.setFieldError('registerPassword', err.message);
			}
		}

		// if (passwordCheck(values.loginUsername, values.loginPassword)) {
		// 	if (setUser) {
		// 		setUser(values.loginUsername);
		// 	}

		// 	handleOnClick();
		// } else {
		// 	formik.setFieldError('loginPassword', 'Username and password do not match.');
		// }

	});
	const formik = useFormik({
		enableReinitialize: true,
		initialValues: {
			loginUsername: '',
			loginPassword: '',
		},
		validate: (values) => {
			const errors: { loginUsername?: string; loginPassword?: string } = {};

			if (!values.loginUsername) {
				errors.loginUsername = 'Required';
			}

			if (!values.loginPassword) {
				errors.loginPassword = 'Required';
			}

			return errors;
		},
		validateOnChange: false,
		onSubmit: async (values) => {
			const loginSuccessfull = await loginValid({
				password: values.loginPassword,
				email: values.loginUsername,
			});
			if (loginSuccessfull === true) {
				if (setUser) {
					setUser(values.loginUsername);
				}
				handleOnClick();
			} else {
				formik.setFieldError('loginPassword', t('loginDataWrong'));
			}

			// if (passwordCheck(values.loginUsername, values.loginPassword)) {
			// 	if (setUser) {
			// 		setUser(values.loginUsername);
			// 	}

			// 	handleOnClick();
			// } else {
			// 	formik.setFieldError('loginPassword', 'Username and password do not match.');
			// }
		},
	});

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const { t } = useTranslation(['translation', 'menu']);

	const handleContinue = () => {
		setIsLoading(true);
		setTimeout(() => {
			if (formik.values.loginUsername === '') {
				formik.setFieldError('loginUsername', t('fillInput'));
			} else {
				setSignInPassword(true);
			}
			setIsLoading(false);
		}, 1000);
	};

	return (
		<PageWrapper
			isProtected={false}
			title={singUpStatus ? 'Sign Up' : 'Login'}
			className={classNames({ 'bg-warning': !singUpStatus, 'bg-info': singUpStatus })}>
			<Page className='p-0'>
				<div className='row h-100 align-items-center justify-content-center'>
					<div className='col-xl-4 col-lg-6 col-md-8 shadow-3d-container'>
						<Card className='shadow-3d-dark' data-tour='login-page'>
							<CardBody>
								<div className='text-center my-5'>
									<Link
										to='/'
										className={classNames(
											'text-decoration-none  fw-bold display-2',
											{
												'text-dark': !darkModeStatus,
												'text-light': darkModeStatus,
											},
										)}>
										<Logo width={200} />
									</Link>
								</div>
								<div
									className={classNames('rounded-3', {
										'bg-l10-dark': !darkModeStatus,
										'bg-dark': darkModeStatus,
									})}>
									<div className='row row-cols-2 g-3 pb-3 px-3 mt-0'>
										<div className='col'>
											<Button
												color={darkModeStatus ? 'light' : 'dark'}
												isLight={singUpStatus}
												className='rounded-1 w-100'
												size='lg'
												onClick={() => {
													setSignInPassword(false);
													setSingUpStatus(!singUpStatus);
												}}>
												Login
											</Button>
										</div>
										<div className='col'>
											<Button
												color={darkModeStatus ? 'light' : 'dark'}
												isLight={!singUpStatus}
												className='rounded-1 w-100'
												size='lg'
												onClick={() => {
													setSignInPassword(false);
													setSingUpStatus(!singUpStatus);
												}}>
												Sign Up
											</Button>
										</div>
									</div>
								</div>

								<LoginHeader isNewUser={singUpStatus} />

								{/* <Alert isLight icon='Lock' isDismissible>
									<div className='row'>
										<div className='col-12'>
											<strong>Username:</strong> {USERS.JOHN.username}
										</div>
										<div className='col-12'>
											<strong>Password:</strong> {USERS.JOHN.password}
										</div>
									</div>
								</Alert> */}
								<form className='row g-4'>
									{singUpStatus ? (
										<>
											<div className='col-12'>
												<FormGroup
													id='registerEmail'
													isFloating
													label='Your email'
												>

													<Input
														autoComplete='Deine Email'
														value={formikRegister.values.registerEmail}
														isTouched={formikRegister.touched.registerEmail}
														invalidFeedback={
															formikRegister.errors.registerEmail
														}
														isValid={formikRegister.isValid}
														onChange={formikRegister.handleChange}
														onBlur={formikRegister.handleBlur}
														onFocus={() => {
															formikRegister.setErrors({});
														}}
													/>
												</FormGroup>
											</div>
											<div className='col-12'>
												<FormGroup
													id='registerFirstName'
													isFloating
													label='Vorname'>

													<Input
														autoComplete='Deine Vorname'
														value={formikRegister.values.registerFirstName}
														isTouched={formikRegister.touched.registerFirstName}
														invalidFeedback={
															formikRegister.errors.registerFirstName
														}
														isValid={formikRegister.isValid}
														onChange={formikRegister.handleChange}
														onBlur={formikRegister.handleBlur}
														onFocus={() => {
															formikRegister.setErrors({});
														}}
													/>
												</FormGroup>
											</div>
											<div className='col-12'>
												<FormGroup
													id='registerLastName'
													isFloating
													label='Dein Nachname'>
													<Input
														autoComplete='Dein Nachname'
														value={formikRegister.values.registerLastName}
														isTouched={formikRegister.touched.registerLastName}
														invalidFeedback={
															formikRegister.errors.registerLastName
														}
														isValid={formikRegister.isValid}
														onChange={formikRegister.handleChange}
														onBlur={formikRegister.handleBlur}
														onFocus={() => {
															formikRegister.setErrors({});
														}}
													/>
												</FormGroup>
											</div>
											<div className='col-12'>
												<FormGroup
													id='registerPassword'
													isFloating
													label='Passwort'>
													<Input
														autoComplete='Wähle ein Passwort'
														value={formikRegister.values.registerPassword}
														isTouched={formikRegister.touched.registerPassword}
														invalidFeedback={
															formikRegister.errors.registerPassword
														}
														isValid={formikRegister.isValid}
														onChange={formikRegister.handleChange}
														onBlur={formikRegister.handleBlur}
														onFocus={() => {
															formikRegister.setErrors({});
														}}
													/>
												</FormGroup>
											</div>
											<div className='col-12'>
												<Button
													color='info'
													className='w-100 py-3'
													onClick={formikRegister.handleSubmit}>
													Sign Up
												</Button>
											</div>
										</>
									) : (
										<>
											<div className='col-12'>
												<FormGroup
													id='loginUsername'
													isFloating
													label='Your email or username'
													className={classNames({
														'd-none': signInPassword,
													})}>
													<Input
														autoComplete='username'
														value={formik.values.loginUsername}
														isTouched={formik.touched.loginUsername}
														invalidFeedback={
															formik.errors.loginUsername
														}
														isValid={formik.isValid}
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														onFocus={() => {
															formik.setErrors({});
														}}
													/>
												</FormGroup>
												{signInPassword && (
													<div className='row'>
														<div
															onClick={() =>
																setSignInPassword(false)
															}>
															Back
														</div>
														<div className='text-center h4 mb-3 fw-bold'>
															Hi, {formik.values.loginUsername}.
														</div>
													</div>
												)}
												<FormGroup
													id='loginPassword'
													isFloating
													label='Password'
													className={classNames({
														'd-none': !signInPassword,
													})}>
													<Input
														type='password'
														autoComplete='current-password'
														value={formik.values.loginPassword}
														isTouched={formik.touched.loginPassword}
														invalidFeedback={
															formik.errors.loginPassword
														}
														validFeedback='Looks good!'
														isValid={formik.isValid}
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
													/>
												</FormGroup>
											</div>
											<div className='col-12'>
												{!signInPassword ? (
													<Button
														color='warning'
														className='w-100 py-3'
														isDisable={!formik.values.loginUsername}
														onClick={handleContinue}>
														{isLoading && (
															<Spinner isSmall inButton isGrow />
														)}
														{t('next')}
													</Button>
												) : (
													<Button
														color='warning'
														className='w-100 py-3'
														onClick={formik.handleSubmit}>
														Login
													</Button>
												)}
											</div>
										</>
									)}
								</form>
							</CardBody>
						</Card>
						<div className='text-center'>
							<a
								href='/'
								className={classNames('text-decoration-none me-3', {
									'link-light': singUpStatus,
									'link-dark': !singUpStatus,
								})}>
								Privacy policy
							</a>
							<a
								href='/'
								className={classNames('link-light text-decoration-none', {
									'link-light': singUpStatus,
									'link-dark': !singUpStatus,
								})}>
								Terms of use
							</a>
						</div>
					</div>
				</div>
			</Page>
		</PageWrapper>
	);
};
Login.propTypes = {
	isSignUp: PropTypes.bool,
};
Login.defaultProps = {
	isSignUp: false,
};

export default Login;
