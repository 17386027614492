import { Columns } from "../../components/Table";
import { FieldType } from "../../components/editWindow";

export interface Slider {
	device_id: number,
	image: string,
	title: string,
	description: string,
	position: number,
	id?: number,
	created_at?: string,
	slide_id?: string,
}

export const SliderColumns: Columns[] = [
	{ dataName: 'ID', name: 'ID' },
	{ dataName: 'device_id', name: 'Geräte ID', fieldType: FieldType.SingleSelect, valuesTeBeSelected: [] },
	{ dataName: 'image', name: 'Bild', fieldType: FieldType.Image },
	{ dataName: 'imageUrl', name: 'Bild' },
	{ dataName: 'title', name: 'Titel', fieldType: FieldType.Switch },
	{ dataName: 'description', name: 'Beschreibung', fieldType: FieldType.Number },
	{ dataName: 'position', name: 'Position', fieldType: FieldType.Number },
	{ dataName: 'created_at', name: 'Erstellt' },
	{ dataName: 'slide_id', name: 'slide_id' },
];

export const emptySlider: Slider = {

	device_id: 0,
	image: "",
	title: "",
	description: "",
	position: 0,
};
