import React, { FC, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { Datatable } from '../../components/DataTable';
import { DeviceColumns, Device, emptyDevice } from '../../models/device/device.model';
import { deleteDevice, duplicateDevice, getAllDevice, postDevice, updateDevice } from '../../models/device/device.repository';

interface BaseDataTableProps {
	isFluid?: boolean;
	name: string;
}
const DeviceTable: FC<BaseDataTableProps> = ({ isFluid, name }) => {
	const { t } = useTranslation(['translation', 'menu']);

	return (
		<>
			<Datatable<Device>
				columns={DeviceColumns}
				getAll={getAllDevice}
				update={updateDevice}
				delete={deleteDevice}
				duplicate={duplicateDevice}
				emptyValue={emptyDevice}
				post={postDevice}
				isFluid={true}
				name={name}
			/>
		</>
	);
};

export default DeviceTable;
