import React, { FC, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { Datatable } from '../../components/DataTable';
import { DeviceColumns, Device, emptyDevice } from '../../models/device/device.model';
import { deleteDevice, duplicateDevice, getAllDevice, postDevice, updateDevice } from '../../models/device/device.repository';
import { Slider, SliderColumns, emptySlider } from '../../models/slides/slider.model';
import { deleteSlider, duplicateSlider, getAllSlider, postSlider, updateSlider } from '../../models/slides/slider.repository';
import { FieldType } from '../../components/editWindow';
import Button from '../../components/bootstrap/Button';
import Select from '../../components/bootstrap/forms/Select';
import { setDevice } from '../../models/login/Login.repository';
import { environment } from '../../common/environment';

interface BaseDataTableProps {
	isFluid?: boolean;
	name: string;
}

const SliderTable: FC<BaseDataTableProps> = ({ isFluid, name }) => {
	const { t } = useTranslation(['translation', 'menu']);
	const [devices, setDevices] = useState<Device[]>([]);
	const [selectedDevice, setSelectedDevice] = useState<string>("");
	useEffect(() => {
		getAllDevice().then((res: Device[]) => {
			setDevices(res)
			if (res.length !== 0) {
				setSelectedDevice(res[0].device_id)

				setDevice(undefined)
			}
		})
	}, []);

	function getColumns() {
		SliderColumns.forEach((column) => {
			if (column.fieldType === FieldType.SingleSelect) {
				const valueForSelect = devices.map(device => {
					return { value: device.id ?? 0, text: device.device_name + " " + device.device_id }
				})
				column.valuesTeBeSelected = valueForSelect;
			}
		});
		return SliderColumns;
	}

	// function changeDevice(device_id: string, deviceList: Device[]) {

	// 	const foundDevice = deviceList.find(device => device.device_id === device.device_id)
	// 	if (foundDevice && foundDevice?.id) {
	// 		setDevice(foundDevice.id)
	// 		console.log(device_id)

	// 		setSelectedDevice(device_id)
	// 	}

	// }

	// function getHeaderButtons() {
	// 	return (
	// 		<Select
	// 			ariaLabel={"Geräte"}
	// 			placeholder={"Geräte"}
	// 			list={devices.map(device => { return { value: device.id, text: device.device_name } })}
	// 			//@ts-ignore
	// 			onChange={(value: any) => {
	// 				changeDevice(value.nativeEvent.target.value, devices)
	// 			}

	// 			}
	// 			value={selectedDevice}
	// 		/>
	// 	)
	// }

	return (
		<>{selectedDevice !== undefined &&
			<Datatable<Slider>
				columns={getColumns()}
				getAll={getAllSlider}
				update={updateSlider}
				delete={deleteSlider}
				duplicate={duplicateSlider}
				// costumRightHeader={getHeaderButtons()}
				emptyValue={emptySlider}
				post={postSlider}
				isFluid={true}
				name={name}
				getPictureUrl={(slider: Slider) => { return environment.backendURL + '/storage/slider/' + slider.slide_id }}
			/>}
		</>
	);
};

export default SliderTable;
