import axios, { AxiosResponse } from 'axios';
import { getTenantID } from '../../common/tenantSelectionService';
import { AbstractRepository } from '../repository/abstract-repository';
import { Receipt } from './receipt';
import moment from 'moment';
import { environment } from '../../common/environment';


export function getUrl(): string {
	return `/receipt`;
}

export function downloadReceipt(receipt: Receipt): Promise<any> {
	return new Promise((resolve, reject) => {
		console.log(receipt)
		window.open(environment.backendURL + '/storage' + '/receipt/' + receipt.receipt_id + '/html', '_blank');
		resolve(true)

	})

}


export function getAllReceipt(): Promise<Receipt[]> {
	return new Promise((resolve, reject) => {
		axios.get(`${getUrl()}`).then((res: AxiosResponse<Receipt[]>) => resolve(res.data));
	});
}

export function deleteReceipt(id: number): Promise<any> {
	const options = {
		headers: {
			'Content-Type': 'application/json',
		}
	};
	return axios.delete(getUrl() + '/' + id, options);
}



