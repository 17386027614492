import axios from 'axios';
import { environment } from '../../common/environment';
import { ArrowBackIos } from '../../components/icon/material-icons';

const getUrl = (): string => {
	return `${environment.backendURL}/auth/login`;
};
const getRegisterUrl = (): string => {
	return `${environment.backendURL}/auth/register`;
};

interface LoginResponse {
	sessionToken: string;
	succesfull: boolean;
	tenant: 0;
	userID: string;
}

export function loginValid(loginData: { email: string; password: string }): Promise<boolean> {
	return new Promise((resolve, reject) => {
		axios.post(getUrl(), loginData).then((res) => {
			const token = res.data.token;
			if (token) {
				setBaseURL(token);
				resolve(true);
			} else {
				reject(res.data)
			}
		}).catch((err: any) => {
			console.log("loginerror", err)
		});
	});
}

export function registerValid(registerData: { email: string; password: string, name: string }): Promise<{ user: { id: number, name: string, email: string, token: string }, message: string }> {
	return new Promise((resolve, reject) => {
		axios.post(getRegisterUrl(), registerData).then((res) => {

			setBaseURL(res.data);
			resolve(res.data);

		}).catch(err => {
			console.log(err.response.data.message)
			reject(err.response.data)
		});
	});
}

export function setBaseURL(token: string): void {
	const baseURL = environment.backendURL
	localStorage.setItem('baseURL', baseURL);
	localStorage.setItem('token', token);
	axios.defaults.baseURL = baseURL;
	axios.defaults.headers.common.Authorization = 'Bearer ' + token;
}


export function setDevice(id: number | undefined) {
	if (id) {
		axios.defaults.headers.common['x-device-id'] = id;
	} else {
		delete axios.defaults.headers.common['x-device-id'];
	}
}