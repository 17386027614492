import { Columns } from "../../components/Table";
import { FieldType } from "../../components/editWindow";

export interface Device {
	id?: number,
	device_name: string,
	device_id: string,
	user_id: number,
	is_active: boolean,
	digital_port: number,
	physical_port: number,
	ip: string,
	slider_time_ms: string,
	receipt_time_ms: string,
	thank_time_ms: string,
	color1: string,
	color2: string,
	color3: string,
}

export const DeviceColumns: Columns[] = [
	{ dataName: 'ID', name: 'ID' },
	{ dataName: 'device_name', name: 'Name', fieldType: FieldType.TextField },
	{ dataName: 'device_id', name: 'device_id' ,fieldType: FieldType.TextField },
	{ dataName: 'is_active', name: 'is_active', fieldType: FieldType.Switch },
	{ dataName: 'digital_port', name: 'digital_port', fieldType: FieldType.Number },
	{ dataName: 'physical_port', name: 'physical_port', fieldType: FieldType.Number },
	{ dataName: 'ip', name: 'ip', fieldType: FieldType.TextField },
	{ dataName: 'slider_time_ms', name: 'slider_time_ms', fieldType: FieldType.Number },
	{ dataName: 'receipt_time_ms', name: 'receipt_time_ms', fieldType: FieldType.Number },
	{ dataName: 'thank_time_ms', name: 'thank_time_ms', fieldType: FieldType.Number },
	{ dataName: 'color1', name: 'color1', fieldType: FieldType.TextField },
	{ dataName: 'color2', name: 'color2', fieldType: FieldType.TextField },
	{ dataName: 'color3', name: 'color3', fieldType: FieldType.TextField },
];

export const emptyDevice: Device = {
	device_name: "",
	device_id: "",
	user_id: 0,
	is_active: true,
	digital_port: 3000,
	physical_port: 9100,
	ip: "",
	slider_time_ms: "",
	receipt_time_ms: "",
	thank_time_ms: "",
	color1: "",
	color2: "",
	color3: "",
};
