import axios, { AxiosResponse } from 'axios';
import { getTenantID } from '../../common/tenantSelectionService';
import { AbstractRepository } from '../repository/abstract-repository';
import { Device } from './device.model';
import moment from 'moment';


export function getUrl(): string {
	return `/device`;
}


export function postDevice(item: Device): Promise<Device> {
	return new Promise((resolve, reject) => {
		axios.post(getUrl(), item).then((res: any) => {
			resolve(res);
		});
	});
}

export function duplicateDevice(item: Device): Promise<Device> {
	item.device_name = item.device_name + "Copy " + moment().format("DD.MM.YYYY HH:mm")
	item.device_id = item.device_id + "Copy " + moment().format("DD.MM.YYYY HH:mm")

	return new Promise(async (resolve, reject) => {
		let createResponse = await postDevice(item)
		resolve(createResponse)
	});
}

export function getAllDevice(): Promise<Device[]> {
	return new Promise((resolve, reject) => {
		axios.get(`${getUrl()}`).then((res: AxiosResponse<Device[]>) => resolve(res.data));
	});
}

export function deleteDevice(id: number): Promise<any> {
	const options = {
		headers: {
			'Content-Type': 'application/json',
		}
	};
	return axios.delete(getUrl() + '/' + id, options);
}

export function updateDevice(item: Device): Promise<Device> {
	return new Promise((resolve, reject) => {
		axios.put(`${getUrl()}/` + item.id, item).then((res: any) => {
			resolve(res);
		});
	});
}

export function buildDevice(
	device_name: string,
	device_id: string,
	user_id: number,
	is_active: boolean,
	digital_port: number,
	physical_port: number,
	ip: string,
	slider_time_ms: string,
	receipt_time_ms: string,
	thank_time_ms: string,
	color1: string,
	color2: string,
	color3: string,
	id?: number,): Device {
	return {
		device_name,
		device_id,
		is_active,
		digital_port,
		physical_port,
		ip,
		slider_time_ms,
		receipt_time_ms,
		thank_time_ms,
		color1,
		color2,
		color3,
		user_id,
		id,
	};
}
